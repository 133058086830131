import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components/macro';
import parse from 'html-react-parser';
import Slide from 'react-reveal/Slide';
import debounce from 'debounce';

import { BLUE, GREY_LIGHT, ORANGE, WHITE } from '../variables';
import { getRandomInt } from '../helpers';
import { useStateValue } from '../state';
import logo from '../static/avast-logo.png';
import bubble from '../static/bubble.svg';
import arrow from '../static/dvojita-sipka.svg';

const pulse = keyframes`
    from { transform: scale(1); opacity: 0.5; };
    50% { transform: scale(1.05); opacity: 1; };
    to { transform: scale(1); opacity: 0.5; };
`;

const animation = () =>
    css`${pulse} 1000ms linear infinite forwards`;

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    /* padding: 90px 56px 200px 56px; */
    /* padding: 90px 56px 0 56px; */
    padding: 50px 0 50px 50px;
    position: absolute;
    top: 0;
    left: 0;
    color: ${BLUE};
    background: ${WHITE};
    transition: transform 500ms;
    overflow: hidden;
`;

const TimelineHeader = styled.header`
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
`;

const HeaderText = styled.div``;

const TimelineHeading = styled.h1`
    font-size: 70px;
    font-weight: 800;
    line-height: 1;
    text-align: center;
    color: ${ORANGE};
`;

const TimelineSubheading = styled.p`
    font-size: 40px;
    color: ${ORANGE};
    line-height: 1;
    text-align: center;
    font-weight: 300;
`;

const Holder = styled.div`
    /* padding-top: 200px; */
    /* padding-top: 120px; */
    padding-bottom: 30px;
    /* margin-bottom: 100px; */
    position: relative;
    overflow: hidden;

    ${props => props.withLine && css`
        width: 377px;
        height: ${props => props.fixed ? '100%' : '670px'};
        position: fixed;
        left: 80px;
        top: ${props => props.fixed ? 0 : '160px'};
        transition: all 500ms;

        &:before {
            content: '';
            display: block;
            width: 3px;
            height: 100%;
            background-color: ${GREY_LIGHT};
            position: absolute;
            top: 0;
            left: 67px;
        }

        &:after {
            width: 100%;
            height: 100px;
            position: absolute;
            left: 0;
            top: -1px;
            background: linear-gradient(
                to bottom,
                white 50%,
                rgba(255, 255, 255, 0)
            );
            content: '';
        }

        /* &:after {
            content: '';
            display: block;
            width: 3px;
            height: 100px;
            background-image: linear-gradient(to bottom, ${WHITE} 30%, transparent);
            position: absolute;
            top: 0;
            left: 67px;
        } */
    `}

    ${props => !props.withLine && css`
        width: calc(100% - 420px);
        padding-top: 120px;
    `};
`;

const TimelineContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 280px;
    flex-shrink: 0;
    min-height: 100px;
    position: relative;
    color: ${ORANGE};
    line-height: 1;

    &:after {
        display: inline-block;
        width: 26px;
        height: 26px;
        margin-right: 15px;
        border-radius: 100%;
        position: absolute;
        top: 5px;
        left: 23px;
        background: ${ORANGE};
        content: '';
    }
`;

const TimelineItem = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 720px;
    padding-top: 40px;
    padding-left: 32px;
    position: relative;
    /* overflow: hidden; */

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
        padding-top: 0;
    }

    &:last-child ${TimelineContent} {
        min-height: auto;
    }
`;

const Year = styled.div`
    position: relative;
    /* font-size: ${props => (props.small ? '17px' : '40px')}; */
    font-size: 40px;
    font-weight: 300;
    white-space: nowrap;
    
    /* ${props =>
    props.small &&
    css`
            transform: translateX(6px);
            line-height: 0;

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                display: inline-block;
                width: 15px;
                height: 15px;
                margin-right: 15px;
                border-radius: 100%;
                background: ${ORANGE};
                content: '';
            }
        `}; */
`;

const Event = styled.div`
    margin-top: 20px;
    font-size: 44px;
    font-weight: 600;
`;

const Description = styled.div`
    font-size: 34px;
    font-weight: 300;
    margin-top: 20px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const ContentItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 50px 50px;
    position: relative;
`;

const Message = styled.div`
    padding-left: 49px;
    position: relative;
    color: ${BLUE};
    font-size: 21px;
    font-weight: 500;
    line-height: 1.2380952381;

    &:before {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 37px;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        background: url(${bubble});
        background-size: cover;
    }
`;

const Text = styled.div`
    width: 370px;

    p {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const Author = styled.div`
    margin-top: 5px;
    color: ${ORANGE};
    font-size: 17px;
    font-weight: 300;
`;

const Photos = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 300px;
`;

const PhotosItem = styled.div`
    border-radius: 100%;

    ${props =>
    props.order === 1 &&
    css`
            width: 170px;
            height: 170px;
            transform: translateY(30px);
        `}

    ${props =>
    props.order === 2 &&
    css`
            width: 240px;
            height: 240px;
            transform: translateY(-30px);
        `}

    ${props =>
    props.order === 3 &&
    css`
            width: 140px;
            height: 140px;
            transform: translateY(80px);
        `}
    background-image: url(${props => props.url});
    background-size: cover;
    background-position: center center;
`;

const MessagePhoto = styled.img`
    height: 100px;
    border-radius: 100%;
    position: absolute;
    right: 100px;
    top: 50%;

    ${props =>
    props.size === 1 &&
    css`
            width: 100px;
            height: 100px;
            transform: translate(-30px, -50%);
        `}
    ${props =>
    props.size === 2 &&
    css`
            width: 140px;
            height: 140px;
            transform: translate(0px, -50%);
        `}
    ${props =>
    props.size === 3 &&
    css`
            width: 175px;
            height: 175px;
            transform: translate(80px, -50%);
        `}
`;

const Logo = styled.img`
    width: 108px;
    height: 108px;
    margin-right: 30px;
`;

const Dots = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const Dot = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    position: fixed;
    left: ${props => (props.x ? props.x : 0)}%;
    top: ${props => (props.y ? props.y : 0)}%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    background: ${props => (props.color ? props.color : '#FF7800')};
    opacity: ${props => (props.opacity ? props.opacity : 1)};
    filter: ${props => `blur(${props.blur ? props.blur : 0}px)`};
`;

export const Button = styled.button`
    position: relative;
    background: transparent;
    width: 121px;
    height: 121px;
    border: 2px solid ${ORANGE};
    color: ${ORANGE};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: 0 auto;
    outline: 0;

    &:before {
        content: '';
        display: block;
        height: 33%;
        width: 2px;
        background-color: ${ORANGE};
        position: absolute;
        top: 35%;
        left: 50%;
        transform: rotate(-45deg);
        transform-origin: bottom right;
    }

    &:after {
        content: '';
        display: block;
        height: 33%;
        width: 2px;
        background-color: ${ORANGE};
        position: absolute;
        top: 35%;
        left: 50%;
        transform: rotate(45deg);
        transform-origin: bottom left;
    }
`;

const Stats = styled.ul``;

const StatItem = styled.li`
    background-color: ${ORANGE};
    color: ${WHITE};
    height: 75px;
    width: 257px;
    padding: 10px;

    &:first-of-type {
        margin-top: 10px;
    }

    &:not(last-of-type) {
        margin-bottom: 10px;
    }
`;

const StatText = styled.p`
    font-size: 21px;
    line-height: 1.25;
`;

const StatNumber = styled.p`
    font-size: 32px;
    font-weight: bold;
`;

const TimelineTexts = styled.div`
    background-color: ${WHITE};
    padding: 20px 0;
    margin: 30px 0;
    position: relative;

    /* &:before {
        content: '';
        display: block;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to top, ${WHITE}, transparent);
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-100%);
    }

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, ${WHITE}, transparent);
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(100%);
    } */
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* height: 540px;
    overflow: hidden; */
`;

const Inner = styled.div`
    width: calc(100% + 17px);
    height: 100%;
    padding-top: 120px;
    box-sizing: content-box;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const TeasingArrow = styled.div`
    width: 50px;
    height: 50px;
    position: absolute;
    left: 44px;
    bottom: 10px;
    z-index: 1;
    border-radius: 100%;
    background: #fff url(${arrow}) no-repeat center center;
    background-size: 50% 50%;
    animation: ${animation};
`;

const Timeline = ({ isSuccess, data }) => {
    let years = [];
    data.timeline.map(item => years.push(item.year.slice(-4)));

    const [, dispatch] = useStateValue();
    const [fixed, setFixed] = useState(false);
    const [hideArrow, setHideArrow] = useState(false);
    const timelineEl = useRef(null);

    const getScrollPos = () => {
        if (window.scrollY > 160) {
            setFixed(true);
        } else {
            setFixed(false);
        }
    };

    const getScrollState = el => {
        const domEl = ReactDOM.findDOMNode(el.current);

        if (domEl.scrollTop > 200) {
            setHideArrow(true);
        } else {
            setHideArrow(false);
        }
    };

    useEffect(() => {
        // const timelineEl = document.querySelector('.timeline');
        window.addEventListener('scroll', debounce(getScrollPos, 50));
        // timelineEl.addEventListener('scroll', debounce(() => console.log(timelineEl), 100));
    });


    const handleContinue = () => {
        dispatch({
            type: 'increaseStep',
        });
    };

    return (
        <Wrapper isVisible={isSuccess}>
            <TimelineHeader>
                <Slide left>
                    <Logo src={logo}/>
                </Slide>
                <Slide right>
                    <HeaderText>
                        <TimelineHeading>It was never just business. </TimelineHeading>
                        <TimelineSubheading>It's always been personal.</TimelineSubheading>
                    </HeaderText>
                </Slide>
            </TimelineHeader>
            <Dots>
                {/* {renderDots(dotsCount)} */}
                <Dot x={115} y={17} size={516} blur={0} color={'#FFAA5D'}/>
                <Dot x={50} y={0} size={300} blur={10} color={'#ffecda'}/>
                <Dot x={82} y={38} size={77} blur={0} color={'#fadec1'}/>
                <Dot x={93} y={50} size={40} blur={0} color={'#fadec1'}/>
                <Dot x={87} y={92} size={80} blur={0} color={'#f4ad6b'}/>
            </Dots>
            <ContentWrapper>
                <Holder withLine fixed={fixed}>
                    {/* <Fade /> */}
                    <Inner ref={timelineEl} onScroll={() => getScrollState(timelineEl)}>
                        {data.timeline.map((item, i) => {
                            const hasMessages = item.messages && item.messages.length > 0;
                            const currentYear = years[i];
                            const nextYear = years[i + 1];

                            return (
                                <TimelineItem key={i} current={currentYear} next={nextYear}>
                                    <TimelineContent empty={!hasMessages}>
                                        <TimelineTexts>
                                            <Year small={!hasMessages}>{item.year}</Year>
                                            <Stats>
                                                {item.stat1_number && (
                                                    <StatItem>
                                                        <StatText>User count</StatText>
                                                        <StatNumber>{item.stat1_number}</StatNumber>
                                                    </StatItem>
                                                )}
                                                {item.stat2_number && (
                                                    <StatItem>
                                                        <StatText>Countries with > 1M users</StatText>
                                                        <StatNumber>{item.stat2_number}</StatNumber>
                                                    </StatItem>
                                                )}
                                                {item.stat3_number && (
                                                    <StatItem>
                                                        <StatText>Paid customers</StatText>
                                                        <StatNumber>{item.stat3_number}</StatNumber>
                                                    </StatItem>
                                                )}
                                            </Stats>
                                            {item.event && (
                                                <Event>
                                                    {item.event.split('\n').map((item, i) => {
                                                        return (
                                                            <span key={i}>
                                                                {item}
                                                                <br/>
                                                            </span>
                                                        );
                                                    })}
                                                </Event>
                                            )}
                                            {item.description && (
                                                <Description>
                                                    {item.description.split('\n').map((item, i) => {
                                                        return (
                                                            <span key={i}>
                                                                {item}
                                                                <br/>
                                                            </span>
                                                        );
                                                    })}
                                                </Description>
                                            )}
                                        </TimelineTexts>
                                    </TimelineContent>
                                </TimelineItem>
                            );
                        })}
                    </Inner>
                    {!hideArrow && <TeasingArrow/>}
                </Holder>

                <Holder>
                    {data.timeline.map((item, i) => {
                        const hasMessages = item.messages && item.messages.length > 0;
                        const hasPhotos = item.photos && item.photos.length > 0;

                        return (
                            <Slide bottom key={i}>
                                <Content>
                                    {hasPhotos && (
                                        <Slide bottom>
                                            <Photos>
                                                {hasPhotos &&
                                                item.photos.map((photo, i) => (
                                                    <PhotosItem key={i} order={i + 1} url={photo}/>
                                                ))}
                                            </Photos>
                                        </Slide>
                                    )}
                                    {hasMessages &&
                                    item.messages.map((message, i) => (
                                        <ContentItem key={i}>
                                            <Slide bottom>
                                                <Message>
                                                    <Text>{parse(message.text)}</Text>
                                                    <Author>{message.author}</Author>
                                                </Message>
                                            </Slide>
                                            {message.photo && (
                                                <Slide bottom>
                                                    <MessagePhoto size={getRandomInt(1, 4)} src={message.photo}/>
                                                </Slide>
                                            )}
                                        </ContentItem>
                                    ))}
                                    {i === data.timeline.length - 1 && (
                                        <Slide bottom>
                                            <Button onClick={handleContinue}/>
                                        </Slide>
                                    )}
                                </Content>
                            </Slide>
                        );
                    })}
                </Holder>
            </ContentWrapper>
        </Wrapper>
    );
};

export default Timeline;
