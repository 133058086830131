import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import data from '../data/data';
import { BLUE, ORANGE } from '../variables';
import rotateIcon from '../static/icon-rotate.svg';

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: ${BLUE};
    padding: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
`;

const RotateIcon = styled.img`
    width: 232px;
    margin-bottom: 55px;
`;

const Heading = styled.h1`
    font-size: 62px;
    line-height: 1.25;
    color: ${ORANGE};
    font-weight: 800;
    max-width: 400px;
`;

const RotateScreen = () => {
    return (
        <Wrapper>
            <RotateIcon src={rotateIcon}/>
            <Heading>{parse(data.rotatescreen.heading)}</Heading>
        </Wrapper>
    );
};

export default RotateScreen;
