import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import AwesomeSlider from 'react-awesome-slider';
import { ORANGE, WHITE } from '../variables';
import parse from 'html-react-parser';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import Button from './Button/Button';

import { useStateValue } from '../state';
import tesla from '../static/slideshow/avast-tesla-vince.jpg';
import fastest from '../static/slideshow/avast-vince-fastest.jpg';
import bungee from '../static/slideshow/avast-vince-bungee.jpg';
import formule from '../static/slideshow/avast-vince-formule.jpg';
import martian from '../static/slideshow/avast-vince-martian.jpg';
import scientist from '../static/slideshow/avast-vince-scientist.jpg';
import shark from '../static/slideshow/avast-vince-shark.jpg';
import showman from '../static/slideshow/avast-vince-showman.jpg';
import spaceX from '../static/slideshow/avast-vince-spaceX.jpg';
import tiger from '../static/slideshow/avast-vince-tiger.jpg';
import tovarna from '../static/slideshow/avast-vince-tovarna.jpg';
import last from '../static/slideshow/last.png';
import data from '../data/data';

const fade = keyframes`
    from { opacity: 0 };
    to { opacity: 1 };
`;

const animation = () =>
    css`
        ${fade}
    `;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Value = styled.div`
    font-size: ${props => (props.length > 11 ? '30px' : props.length > 8 ? '36px' : '50px')};
    font-weight: 600;
    line-height: 1.1;
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    strong {
        font-weight: bold;
    }

    sup {
        display: inline-block;
        transform: translateY(-25%);
        font-size: 0.875em;
    }
`;

const Column = styled.div`
    width: 290px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 135px 30px;
    color: ${WHITE};
    background: ${ORANGE};

    ${props =>
    props.size < 3 &&
    css`
            ${Item} {
                justify-content: flex-start;

                > div {
                    margin-bottom: 80px;
                }
            }
        `};
`;

const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 280px;
    padding: 35px;
    border-radius: 100%;
    position: absolute;
    top: ${props => props.y}%;
    left: ${props => props.x}%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    line-height: 1.3;
    color: ${WHITE};
    background: ${ORANGE};
`;

const Final = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: ${WHITE};
    text-align: center;
    background: url(${last}) no-repeat;
    background-size: cover;
    animation: ${animation} 1000ms;
`;

const Heading = styled.strong`
    display: block;
    margin: 0.4em 0;
    font-size: ${props => (props.big ? '100px' : '38px')};
    line-height: 1.2;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 50px;

    a {
        margin: 10px;
    }
`;

const getImage = name => {
    let image;
    switch (name) {
        case 'tesla':
            image = tesla;
            break;
        case 'fastest':
            image = fastest;
            break;
        case 'bungee':
            image = bungee;
            break;
        case 'formule':
            image = formule;
            break;
        case 'martian':
            image = martian;
            break;
        case 'scientist':
            image = scientist;
            break;
        case 'shark':
            image = shark;
            break;
        case 'showman':
            image = showman;
            break;
        case 'spaceX':
            image = spaceX;
            break;
        case 'tiger':
            image = tiger;
            break;
        case 'tovarna':
            image = tovarna;
            break;
        case 'last':
            image = last;
            break;
        default:
            break;
    }
    return image;
};

const Slideshow = () => {
    const [final, setFinal] = useState(false);
    const [, dispatch] = useStateValue();

    const onAnimationStart = (props) => {
        if (props.nextIndex === 11) {
            setFinal(true);
        }
    };

    const handleStart = () => {
        dispatch({
            type: 'setHomepageStep',
        });
    };

    const handleTimeline = () => {
        dispatch({
            type: 'setTimelineStep',
        });
    };

    return (
        <Wrapper>
            {!final && (
                <AwesomeSlider bullets={false} fillParent={true} infinite={false} onTransitionStart={onAnimationStart}>
                    {data.slideshow.map((item, i) => (
                        <div key={i} data-src={getImage(item.image)}>
                            {item.facts && item.facts.length > 0 && (
                                <Column size={item.facts.length}>
                                    <Item>
                                        {item.facts.map((fact, i) => (
                                            <Slide key={i} right cascade>
                                                <div>
                                                    <div>{parse(fact.pretext)}</div>
                                                    <Value length={fact.value.length}>{parse(fact.value)}</Value>
                                                    <div>{parse(fact.posttext)}</div>
                                                </div>
                                            </Slide>
                                        ))}
                                    </Item>
                                </Column>
                            )}
                            {item.claim && (
                                <Circle x={item.claim.x} y={item.claim.y}>
                                    {parse(item.claim.text)}
                                </Circle>
                            )}
                        </div>
                    ))}
                </AwesomeSlider>
            )}
            {final && (
                <Final>
                    <Fade right cascade>
                        <Heading>{parse(data.slideshow[11].final[0])}</Heading>
                        <Heading>{parse(data.slideshow[11].final[1])}</Heading>
                        <Heading big>{parse(data.slideshow[11].final[2])}</Heading>
                        <Heading>{parse(data.slideshow[11].final[3])}</Heading>
                        <Buttons>
                            <Button transparent onClick={() => handleStart()}>Start over</Button>
                            <Button white onClick={() => handleTimeline()}>Go to timeline</Button>
                        </Buttons>
                    </Fade>
                </Final>
            )}
        </Wrapper>
    );
};

export default Slideshow;