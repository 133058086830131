import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import parse from 'html-react-parser';
import data from '../../data/data';

import { WHITE } from '../../variables';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 35px;
`;

const WrapperFinished = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;


const CountDownItem = styled.div`
    color: ${WHITE};
    font-size: 78px;
    font-weight: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;
    margin: 10px;
    padding-top: 10px;
    position: relative;
    width: 200px;
    height: 200px;

    &:before {
        content: '';
        display: block;
        width: 194px;
        height: 194px;
        border: 1px solid ${WHITE};
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 3px;
    }

    span {
        font-size: 28px;
        font-weight: 600;
        text-transform: uppercase;
        position: absolute;
        bottom: -45px;
        left: 50%;
        transform: translateX(-50%);
    }
`;

const CountDownFinishedItem = styled.div`
    color: ${WHITE};
    font-size: 56px;
    letter-spacing: normal;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;
    margin: 10px;
    padding-top: 10px;
    margin-bottom: 31.25px;
    position: relative;
    width: 200px;
    height: 200px;

    &:before {
        content: '';
        display: block;
        width: 194px;
        height: 194px;
        border: 6.5px solid ${WHITE};
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 3px;
    }

    span {
        font-size: 28px;
        font-weight: 300;
        text-transform: uppercase;
        position: absolute;
        bottom: -45px;
        left: 50%;
        transform: translateX(-50%);
    }
`;

const CountDownFinishedLabel = styled.div`
    display: block;
    color: white;

    span{
        font-size: 42px;
        font-weight: bold;
    }
`;

const CountDownSVG = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 200px;

    path {
        fill: none
        stroke: ${WHITE};
        stroke-width: 6
    }
`;

// const handleFinished = () => {
//     dispatch({
//         type: 'setCountdownFinished',
//     });
// };

export default class Countdown extends React.Component {
    state = {
        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined,
        timestamp: undefined,
        finished: false,
    };


    componentDidMount() {
        this.interval = setInterval(() => {
            const { timeTillDate, timeFormat } = this.props;
            const then = moment(timeTillDate, timeFormat);
            const now = moment();
            let timestamp = (then.diff(now, 'miliseconds')) / 1000;
            let countdown = Math.abs(timestamp);
            timestamp = Math.floor(timestamp);

            let days = Math.floor(countdown / 86400);
            countdown -= days * 86400;
            let hours = Math.floor(countdown / 3600) % 24;
            countdown -= hours * 3600;
            let minutes = Math.floor(countdown / 60) % 60;
            countdown -= minutes * 60;
            let seconds = Math.floor(countdown % 60).toString();
            days = days.toString();
            hours = hours.toString();
            minutes = minutes.toString();

            this.setState({ days, hours, minutes, seconds, timestamp });
        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { days, hours, minutes, seconds } = this.state;
        const daysRadius = mapNumber(days, 30, 0, 0, 360);
        const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
        const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
        const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

        if (!seconds) {
            return null;
        }

        if (this.state.timestamp < 0) {

            if (!this.state.finished) {
                clearInterval(this.interval);
                this.setState({ finished: true });
                this.props.onFinish();
            }

            return (
                <WrapperFinished>
                    <CountDownFinishedItem>
                        {parse(data.countdown.finished.circle)}
                    </CountDownFinishedItem>
                    <CountDownFinishedLabel>
                        <span>{parse(data.countdown.finished.subcircle)}</span>
                    </CountDownFinishedLabel>
                </WrapperFinished>
            );
        } else {
            return (
                <Wrapper>
                    {days && (
                        <CountDownItem>
                            <SVGCircle radius={daysRadius}/>
                            {days}
                            <span>{parse(data.countdown.days)}</span>
                        </CountDownItem>
                    )}
                    {hours && (
                        <CountDownItem>
                            <SVGCircle radius={hoursRadius}/>
                            {hours}
                            <span>{parse(data.countdown.hours)}</span>
                        </CountDownItem>
                    )}
                    {minutes && (
                        <CountDownItem>
                            <SVGCircle radius={minutesRadius}/>
                            {minutes}
                            <span>{parse(data.countdown.minutes)}</span>
                        </CountDownItem>
                    )}
                    {seconds && (
                        <CountDownItem>
                            <SVGCircle radius={secondsRadius}/>
                            {seconds}
                            <span>{parse(data.countdown.seconds)}</span>
                        </CountDownItem>
                    )}
                </Wrapper>
            );
        }

    }
}

const SVGCircle = ({ radius }) => (
    <CountDownSVG className="countdown-svg">
        <path d={describeArc(100, 100, 96, 0, radius)}/>
    </CountDownSVG>
);

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
    };
}

function describeArc(x, y, radius, startAngle, endAngle) {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    var d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');

    return d;
}

function mapNumber(number, in_min, in_max, out_min, out_max) {
    return ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}