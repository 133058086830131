import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useDrag } from 'react-dnd';

import { getRandomInt } from '../../helpers';
import plzen from '../../static/plzen.png';
import golf from '../../static/golf.png';
import mac from '../../static/mac.png';
import lambo from '../../static/lambo.png';
import ipad from '../../static/ipad.png';

const resolveCss = type => {
    let currentCss;
    switch (type) {
        case 'plzen':
            currentCss = css`
                width: 142px;
                height: 144px;
                top: 500px;
                left: 35px;
                background-image: url(${plzen});
            `;
            break;

        case 'mac':
            currentCss = css`
                width: 138px;
                height: 111px;
                top: 360px;
                left: 0;
                background-image: url(${mac});
            `;
            break;

        case 'lambo':
            currentCss = css`
                width: 154px;
                height: 82px;
                top: 210px;
                left: 40px;
                background-image: url(${lambo});
            `;
            break;

        case 'golf':
            currentCss = css`
                width: 165px;
                height: 146px;
                top: 50px;
                left: 150px;
                background-image: url(${golf});
            `;
            break;

        case 'ipad':
            currentCss = css`
                width: 119px;
                height: 124px;
                top: 610px;
                left: 210px;
                background-image: url(${ipad});
            `;
            break;

        default:
            break;
    }
    return currentCss;
};

const Wrapper = styled.div`
    ${props => resolveCss(props.type)};
    position: absolute;
    background-size: cover;
    transition: transform 500ms;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
`;

const DragItem = ({ type }) => {
    const [{ transform }, dragRef] = useDrag({
        item: { type },
        collect: monitor => ({
            transform: monitor.isDragging() ? 'scale(1.2)' : 'scale(1)',
            isDragging: !!monitor.isDragging(),
        }),
    });

    return <Wrapper delay={getRandomInt(5000, 8000)} type={type} ref={dragRef} style={{ transform }}/>;
};

export default DragItem;