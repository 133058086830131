import React from 'react'
import styled, { css } from 'styled-components'
import { WHITE, BLUE, ORANGE } from '../../variables'

export const Wrapper = styled.a`
    padding: 15px 30px;
    font-size: 21px;
    border: 0;
    font-weight: 600;
    text-transform: uppercase;
    outline: 0;
    box-sizing: border-box;

    ${props => props.white && css`
        color: ${ORANGE};
        background: ${WHITE};
    `}

    ${props => props.transparent && css`
        color: ${WHITE};
        border: 1px solid ${WHITE};
        background: transparent;
    `}

    &:active {
        color: ${props => (props.transparent ? ORANGE : WHITE)};
        background: ${props => (props.transparent ? WHITE : BLUE)};
    }
`;

const Button = ({ children, transparent, white, onClick }) => (
    <Wrapper transparent={transparent} white={white} onClick={onClick}>{children}</Wrapper>
)

export default Button