import React from 'react';
import styled, { css } from 'styled-components';
import parse from 'html-react-parser';
import data from '../data/data';
import Slide from 'react-reveal/Slide';

import logo from '../static/avast-logo.png';
import { ReactComponent as IconPeople } from '../static/icon-people.svg';
import { ReactComponent as IconProtection } from '../static/icon-protection.svg';
import award from '../static/award.png';
import { ORANGE, BLUE, LIGHT_BLUE, WHITE, DASH_COLOR, GREEN, GREY } from '../variables';
import { useStateValue } from '../state';

const Wrapper = styled.div`
    background-color: ${LIGHT_BLUE};
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    padding: 75px;
`;

const Logo = styled.img`
    width: 149px;
    height: 149px;
    margin-right: 40px;
`;

const HeaderText = styled.div`
    font-weight: 300;
    color: ${BLUE};
    font-size: 34px;
`;

const Heading = styled.h1`
    color: ${ORANGE};
    font-weight: 800;
    font-size: 70px;
`;

const Subheading = styled.p``;

const Content = styled.div`
    display: flex;
    margin-bottom: 100px;
    padding: 0 75px;
`;

const MainColumnLeft = styled.div`
    width: calc(100% / 3);
    padding-right: 20px;

    > div {
        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }
`;

const MainColumnRight = styled.div`
    width: calc((100% / 3) * 2);
    padding-left: 20px;

    > div {
        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }
`;

const Number = styled.p`
    color: ${BLUE};
    font-weight: bold;
    font-size: 40px;
    position: relative;
    padding-right: 20px;
    margin-right: 20px;
    &:after {
        content: '';
        display: block;
        width: 2px;
        height: 36px;
        background-color: ${DASH_COLOR};
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
`;

const NumberCard = styled.div`
    background-color: ${WHITE};
    width: 100%;
    height: 85px;
    padding: 20px 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
`;

const CardHeading = styled.h2`
    font-size: 42px;
    line-height: 1;
    font-weight: bold;
    color: ${WHITE};
    margin-bottom: 20px;

    ${props =>
        props.smallMargin &&
        css`
            margin-bottom: 5px;
        `};
`;

const CardText = styled.p`
    font-size: 17px;
    line-height: 23px;
    font-weight: 300;

    span {
        display: block;
    }

    strong {
        font-weight: bold;
    }
`;

const TextLarge = styled.p`
    color: ${ORANGE};
    font-weight: bold;
    line-height: 1;
    padding-right: 20px;
    position: absolute;
    top: 30px;
    left: -368px;
    width: 686px;
    font-size: 80px;
`;

const Card = styled.div`
    padding: 25px 30px;
    display: flex;
    flex-direction: column;
    ${props =>
        props.height &&
        css`
            height: ${props.height}px;
        `};
    ${CardText} {
        color: ${WHITE};
    }
    ${props =>
        props.blue
            ? css`
                  background-color: ${BLUE};
                  ${CardHeading} {
                      color: ${ORANGE};
                  }
              `
            : `background-color: ${ORANGE}`};
    ${props =>
        props.card_5 &&
        css`
            ${CardImage} {
                margin-bottom: 25px;
            }

            ${CardHeading} {
                padding-right: 50px;
            }
        `};

    ${props =>
        props.card_7 &&
        css`
            ${CardImage} {
                margin-bottom: 25px;
            }
        `};

    ${props =>
        props.card_8 &&
        css`
            ${CardImage} {
                margin-top: auto;
            }
        `};

    ${props =>
        props.reverseOrder &&
        css`
            flex-direction: column-reverse;

            ${CardHeading} {
                margin-bottom: 0;
            }

            ${CardText} {
                margin-bottom: 15px;
            }
        `};
`;

const ColumnHalf = styled.div`
    position: relative;
    ${props =>
        props.spaceBetween &&
        css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        `};
`;

const RowHalf = styled.div`
    display: flex;
    justify-content: space-between;
    ${ColumnHalf} {
        width: calc(50% - 20px);
    }
`;

const CardImage = styled.img`
    ${props =>
        props.width &&
        css`
            width: ${props.width};
        `};
`;

const CardIcon = styled.div`
    svg {
        width: 147px;
        height: 147px;
    }
`;

const Bottom = styled.div`
    text-align: center;
    background-color: ${BLUE};
    padding: 97px 0;
`;

const BottomHeading = styled.h2`
    font-size: 40px;
    line-height: 1;
    color: ${WHITE};
    font-weight: bold;
    margin-bottom: 35px;
`;

const Button = styled.button`
    background-color: ${GREEN};
    border: 0;
    padding: 0;
    overflow: hidden;
    color: ${WHITE};
    font-weight: 600;
    text-transform: uppercase;
    width: 300px;
    height: 85px;
    font-size: 20px;

    span {
        display: block;
    }
`;

const DisclaimerText = styled.p`
    color: ${GREY};
    font-size: 17px;
    font-style: italic;
    margin-top: 35px;
`;

const Dashboard = () => {
    const [, dispatch] = useStateValue();

    const handleSuccess = () => {
        dispatch({
            type: 'increaseStep',
        });
    };

    return (
        <Wrapper>
            <Header>
                <Slide left>
                    <Logo src={logo} />
                </Slide>
                <Slide right>
                    <HeaderText>
                        <Heading>{parse(data.dashboard.heading)}</Heading>
                        <Subheading>{parse(data.dashboard.subheading)}</Subheading>
                    </HeaderText>
                </Slide>
            </Header>
            <Content>
                <MainColumnLeft>
                    <Slide bottom>
                        <NumberCard>
                            <Number>{parse(data.dashboard.card_1.number)}</Number>
                            <CardText>{parse(data.dashboard.card_1.text)}</CardText>
                        </NumberCard>
                    </Slide>
                    <Slide bottom>
                        <Card height="210">
                            <CardHeading>{parse(data.dashboard.card_9.heading)}</CardHeading>
                            <CardText>{parse(data.dashboard.card_9.text)}</CardText>
                        </Card>
                    </Slide>
                    <Slide bottom>
                        <NumberCard>
                            <Number>{parse(data.dashboard.card_4.number)}</Number>
                            <CardText>{parse(data.dashboard.card_4.text)}</CardText>
                        </NumberCard>
                    </Slide>
                    <Slide bottom>
                        <Card height="560" blue card_5>
                            <CardIcon>
                                <IconPeople />
                            </CardIcon>
                            <CardHeading>{parse(data.dashboard.card_5.heading)}</CardHeading>
                            <CardText>{parse(data.dashboard.card_5.text)}</CardText>
                        </Card>
                    </Slide>
                    <Slide bottom>
                        <NumberCard>
                            <Number>{parse(data.dashboard.card_6.number)}</Number>
                            <CardText>{parse(data.dashboard.card_6.text)}</CardText>
                        </NumberCard>
                    </Slide>
                </MainColumnLeft>
                <MainColumnRight>
                    <RowHalf>
                        <ColumnHalf>
                            <Slide bottom>
                                <Card height="340" blue card_7>
                                    <CardIcon>
                                        <IconProtection />
                                    </CardIcon>
                                    <CardHeading>{parse(data.dashboard.card_7.heading)}</CardHeading>
                                    <CardText>{parse(data.dashboard.card_7.text)}</CardText>
                                </Card>
                            </Slide>
                        </ColumnHalf>
                        <ColumnHalf>
                            <Slide bottom>
                                <Card height="340" card_8>
                                    <CardHeading smallMargin>{parse(data.dashboard.card_8.heading)}</CardHeading>
                                    <CardText>{parse(data.dashboard.card_8.text_1)}</CardText>
                                    <CardText>{parse(data.dashboard.card_8.text_2)}</CardText>
                                    <CardText>{parse(data.dashboard.card_8.text_3)}</CardText>
                                    <CardImage src={award} width="160" />
                                </Card>
                            </Slide>
                        </ColumnHalf>
                    </RowHalf>
                    <RowHalf>
                        <ColumnHalf>
                            <Slide bottom>
                                <Card height="335">
                                    <CardHeading>{parse(data.dashboard.card_3.heading)}</CardHeading>
                                    <CardText>{parse(data.dashboard.card_3.text)}</CardText>
                                </Card>
                            </Slide>
                        </ColumnHalf>
                        <ColumnHalf spaceBetween>
                            <Slide bottom>
                                <NumberCard>
                                    <Number>{parse(data.dashboard.card_10.number)}</Number>
                                    <CardText>{parse(data.dashboard.card_10.text)}</CardText>
                                </NumberCard>
                            </Slide>
                            <Slide bottom>
                                <NumberCard>
                                    <Number>{parse(data.dashboard.card_2.number)}</Number>
                                    <CardText>{parse(data.dashboard.card_2.text)}</CardText>
                                </NumberCard>
                            </Slide>
                            <Slide bottom>
                                <NumberCard>
                                    <Number>{parse(data.dashboard.card_11.number)}</Number>
                                    <CardText>{parse(data.dashboard.card_11.text)}</CardText>
                                </NumberCard>
                            </Slide>
                        </ColumnHalf>
                    </RowHalf>
                    <Slide bottom>
                        <NumberCard>
                            <Number>{parse(data.dashboard.card_12.number)}</Number>
                            <CardText>{parse(data.dashboard.card_12.text)}</CardText>
                        </NumberCard>
                    </Slide>
                    <Slide bottom>
                        <NumberCard>
                            <Number>{parse(data.dashboard.card_13.number)}</Number>
                            <CardText>{parse(data.dashboard.card_13.text)}</CardText>
                        </NumberCard>
                    </Slide>
                    <Slide bottom>
                        <Card height="180" reverseOrder>
                            <CardHeading>{parse(data.dashboard.card_14.heading)}</CardHeading>
                            <CardText>{parse(data.dashboard.card_14.text)}</CardText>
                        </Card>
                    </Slide>
                    <RowHalf>
                        <Slide bottom>
                            <ColumnHalf>
                                <TextLarge>Vince, we’re going to miss you.</TextLarge>
                            </ColumnHalf>
                        </Slide>
                        <ColumnHalf>
                            <Slide bottom>
                                <Card height="215" blue>
                                    <CardHeading>{parse(data.dashboard.card_16.heading)}</CardHeading>
                                    <CardText>{parse(data.dashboard.card_16.text)}</CardText>
                                </Card>
                            </Slide>
                        </ColumnHalf>
                    </RowHalf>
                </MainColumnRight>
            </Content>
            <Bottom>
                <BottomHeading>{parse(data.dashboard.bottom.heading)}</BottomHeading>
                <Button onClick={() => handleSuccess()}>{parse(data.dashboard.bottom.button)}</Button>
                <DisclaimerText>{parse(data.dashboard.bottom.disclaimer)}</DisclaimerText>
            </Bottom>
        </Wrapper>
    );
};

export default Dashboard;
