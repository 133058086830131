import React from 'react';
import Countdown from './Countdown/Countdown';
import styled from 'styled-components';
import parse from 'html-react-parser';
import data from '../data/data';
import Fade from 'react-reveal/Fade';
import { Button as Arrow } from './Timeline';

import { ORANGE } from '../variables';
import { useStateValue } from '../state';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Header = styled.header`
    text-align: center;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Heading = styled.h1`
    font-size: 52px;
    color: ${ORANGE};
    font-weight: bold;
`;

const SubHeading = styled.p`
    color: ${ORANGE};
    opacity: 0.5;
    font-size: 48px;
    font-weight: 300;
`;

const CountDownStripe = styled.div`
    background-color: ${ORANGE};
    height: ${props => props.countdownFinished ? '45%' : '50%'};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Bottom = styled.div`
    height: 25%
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Bubbles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
`;

const Bubble = styled.div`
    position: absolute;
    background-color: ${ORANGE};
    border-radius: 50%;
    ${props =>
    props.size &&
    `
        width: ${props.size}px;
        height: ${props.size}px;
    `};
    ${props =>
    props.top &&
    `
        top: ${props.top}px;
    `};
    ${props =>
    props.right &&
    `
        right: ${props.right}px;
    `};
    ${props =>
    props.bottom &&
    `
        bottom: ${props.bottom}px;
    `};
    ${props =>
    props.left &&
    `
        left: ${props.left}px;
    `};
    ${props =>
    props.opacity &&
    `
        opacity: ${props.opacity};
    `};
`;

const Button = styled.button`
    position: relative;
    z-index: 3;
    border: 2px solid ${ORANGE};
    padding: 20px;
    background: transparent;
    color: ${ORANGE};
    font-size: 17px;
    text-transform: uppercase;
    outline: 0;
    font-weight: 600;
`;

const Final = styled.div`
    margin: 40px 0 20px 0;
	color: ${ORANGE};
	font-size: 40px;
	font-weight: 300;
	text-align: center;
`;

const StyledArrow = styled(Arrow)`
    width: 75px;
    height: 75px;
`;

const CountDown = () => {
    const [{ countdownFinished }, dispatch] = useStateValue();

    const handleContinue = () => {
        dispatch({
            type: 'increaseStep',
        });
    };

    const handleCountdownFinish = () => {
        dispatch({
            type: 'setCountdownFinished',
        });
    };

    return (
        <Wrapper>
            <Header>
                <Fade bottom cascade>
                    <>
                        <Heading>{countdownFinished ? parse(data.countdown.finished.heading) : parse(data.countdown.heading)}</Heading>
                        {!countdownFinished && <SubHeading>{parse(data.countdown.subheading)}</SubHeading>}
                    </>
                </Fade>
            </Header>
            <CountDownStripe countdownFinished={countdownFinished}>
                <Fade>
                    <Countdown timeTillDate="30 06 2019, 23:59" timeFormat="DD MM YYYY, hh:mm"
                               onFinish={handleCountdownFinish} isFinished={countdownFinished}/>
                </Fade>
            </CountDownStripe>
            <Bottom>
                {!countdownFinished && <Button onClick={() => handleContinue()}>Click to discover the future</Button>}
                {countdownFinished &&
                <>
                    <Final>{parse(data.countdown.finished.final)}</Final>
                    <StyledArrow onClick={handleContinue}/>
                </>
                }
            </Bottom>
            <Bubbles>
                <Bubble size="25" top="-10" left="140" opacity="0.5"/>
                <Bubble size="20" top="40" right="75" opacity="0.3"/>
                <Bubble size="45" top="175" right="145" opacity="0.3"/>
                <Bubble size="140" top="160" right="35" opacity="0.15"/>
                <Bubble size="44" bottom="140" left="60" opacity="0.15"/>
                <Bubble size="20" bottom="100" left="-10" opacity="0.5"/>
                <Bubble size="150" bottom="-100" left="175" opacity="0.3"/>
            </Bubbles>
        </Wrapper>
    );
};

export default CountDown;
