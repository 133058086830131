import React from 'react';
import { Helmet } from 'react-helmet';
import TouchBackend from 'react-dnd-touch-backend';
import { DragDropContext } from 'react-dnd';
import 'reset-css';
import './App.css';
import { StateProvider } from './state';

import Homepage from './pages/Homepage';
// import data from './data/data';

const App = () => {

    const initialState = {
        dragCompleted: false,
        introCompleted: false,
        currentStep: 1,
        dragging: false,
        countdownFinished: false,
        timeline: {
            timeline: [],
            stats: [],
        },
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'completeDrag':
                return {
                    ...state,
                    dragCompleted: true,
                };

            case 'completeIntro':
                return {
                    ...state,
                    introCompleted: true,
                };

            case 'increaseStep':
                return {
                    ...state,
                    currentStep: state.currentStep + 1,
                };

            case 'setHomepageStep':
                return {
                    ...state,
                    currentStep: 1,
                };

            case 'setTimelineStep':
                return {
                    ...state,
                    currentStep: 3,
                };

            case 'setTimeline':
                return {
                    ...state,
                    timeline: action.timeline,
                };

            case 'setDragging':
                return {
                    ...state,
                    dragging: true,
                };

            case 'setCountdownFinished':
                return {
                    ...state,
                    countdownFinished: true,
                };

            default:
                return state;
        }
    };

    return (
        <StateProvider initialState={initialState} reducer={reducer}>
            <Helmet>
                <title>Vince digital gift</title>
                <meta name="viewport"
                      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
            </Helmet>
            <div className="App">
                <Homepage/>
            </div>
        </StateProvider>
    );
};

export default DragDropContext(TouchBackend)(App);