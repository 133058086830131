import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import styled, { keyframes, css } from 'styled-components/macro';
import Fade from 'react-reveal/Fade';

import { useStateValue } from '../state';
import { WHITE, ORANGE, BLUE } from '../variables';
import data from '../data/data';
import DragItem from './DragAndDrop/DragItem';
import DropZone from './DragAndDrop/DropZone';
import kruhy from '../static/kruhy.png';
import ipad from '../static/avast-ipod-achievment-3.png';

const fade = keyframes`
    from { opacity: 0 };
    to { opacity: 1 };
`;

const animation = () =>
    css`
        ${fade}
    `;
const Wrapper = styled.section`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
`;

const Heading = styled.h1`
    margin-bottom: 28px;
    font-size: 70px;
    font-weight: 800;
    line-height: 1;
    color: ${ORANGE};
`;

const Subheading = styled.p`
    margin-bottom: 20px;
    font-size: 34px;
    font-weight: 300;
    line-height: 1.1764705882;
    color: ${BLUE};
`;

const HeadingSecondary = styled.p`
    margin-bottom: 28px;
    color: ${BLUE};
    font-size: 40px;
    font-weight: 600;
    max-width: 600px;
    line-height: 1;
`;

const Items = styled.div`
    height: 100%;
    width: 420px;
    position: absolute;
    top: 0;
    right: 0;
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    animation: ${animation} 2000ms;
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    padding: 80px 105px;
    ${props => props.blurred && 'filter: blur(10px)'};
    background: ${WHITE} url(${kruhy});
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: right center;
`;

const Box = styled.div`
    width: 714px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 70px 100px 70px 180px;
    position: relative;
    transform: translateX(160px);
    background: ${BLUE};
`;

const BoxHeading = styled.p`
    margin-bottom: 30px;
    font-size: 44px;
    font-weight: 800;
    line-height: 1.2;
    color: ${ORANGE};
`;

const Button = styled.button`
    margin-right: 17px;
    padding: 15px 30px;
    font-size: 21px;
    border: 0;
    color: ${WHITE};
    font-weight: 600;
    text-transform: uppercase;
    background: ${props => (props.transparent ? 'transparent' : ORANGE)};
    ${props => props.transparent && `border 1px solid ${ORANGE}`};
    outline: 0;

    &:active {
        color: ${props => (props.transparent ? WHITE : BLUE)};
        background: ${props => (props.transparent ? ORANGE : WHITE)};
    }
`;

const Buttons = styled.div`
    display: flex;
`;

const Image = styled.img`
    width: 482px;
    height: 542px;
    position: absolute;
    left: -350px;
    bottom: -130px;
`;

const items = ['golf', 'lambo', 'mac', 'plzen', 'ipad'];

const Intro = () => {
    const [{ dragCompleted }, dispatch] = useStateValue();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const handleContinue = () => {
        dispatch({
            type: 'increaseStep',
        });
    };

    return (
        <Wrapper>
            <Content blurred={dragCompleted}>
                <Fade bottom cascade>
                    <Heading>{parse(data.intro.heading)}</Heading>
                    <Subheading>{parse(data.intro.subheading)}</Subheading>
                    <HeadingSecondary>{parse(data.intro.question)}</HeadingSecondary>
                </Fade>

                <Items>
                    {items.map((item, i) => (
                        <DragItem type={item} key={i} order={i + 1} onClick={() => console.log('xxx')} />
                    ))}
                </Items>

                <DropZone />
            </Content>
            {dragCompleted && (
                <Overlay>
                    <Box>
                        <BoxHeading>{parse(data.intro.overlay.heading)}</BoxHeading>
                        <Buttons>
                            <Button onClick={handleContinue}>{parse(data.intro.overlay.button_1)}</Button>
                            <Button onClick={handleContinue} transparent>
                                {parse(data.intro.overlay.button_2)}
                            </Button>
                        </Buttons>
                        <Image src={ipad} />
                    </Box>
                </Overlay>
            )}
        </Wrapper>
    );
};

export default Intro;
