import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { CSSTransition } from 'react-transition-group';

import { useStateValue } from '../state';
import Intro from '../components/Intro';
import Timeline from '../components/Timeline';
import Dashboard from '../components/Dashboard';
import CountDown from '../components/Countdown';
import RotateScreen from '../components/RotateScreen';
import Slideshow from '../components/Slideshow';

const Wrapper = styled.div`
    width: 100%;
    min-height: 100%;
`;

const Homepage = () => {
    const [{ currentStep, timeline }, dispatch] = useStateValue();
    const [portrait, setPortrait] = useState(false);

    const detectOrientation = () => {
        setTimeout(() => {
            if (window.innerHeight > window.innerWidth) {
                setPortrait(true);
            } else {
                setPortrait(false);
            }
        }, 50);
    };

    useEffect(() => {
        if (!Array.isArray(timeline.timeline) || timeline.timeline.length === 0) {
            fetch('/timeline.json')
                .then(function(response) {
                    return response.json();
                })
                .then(function(obj) {
                    dispatch({
                        type: 'setTimeline',
                        timeline: obj,
                    });
                });
        }
        detectOrientation();
        window.addEventListener('orientationchange', () => detectOrientation());
    });

    return (
        <Wrapper>
            {currentStep === 1 && <Dashboard />}
            {currentStep === 2 && <Intro />}
            {currentStep === 3 && <Timeline data={timeline} />}
            {currentStep === 4 && <CountDown />}
            {currentStep === 5 && <Slideshow />}
            <CSSTransition in={portrait} timeout={0} classNames="rotate-overlay" unmountOnExit>
                <RotateScreen />
            </CSSTransition>
        </Wrapper>
    );
};

export default Homepage;
