import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import styled, { css, keyframes } from 'styled-components/macro';

import { useStateValue } from '../../state';
import box from '../../static/box.png';

const pulse = keyframes`
    from { transform: scale(1) };
    50% { transform: scale(1.05) };
    to { transform: scale(1) };
`;

const shake = keyframes`
    from { transform: translateX(0) };
    20% { transform: translateX(-60px) };
    40% { transform: translateX(60px) };
    60% { transform: translateX(-60px) };
    80% { transform: translateX(60px) };
    to { transform: translateX(0) };
`;

const animation = () =>
    css`${pulse} 3000ms linear infinite forwards`;

const shakeAnimation = () =>
    css`${shake} 600ms linear`;

const Box = styled.div`
    width: 632px;
    height: 284px;
    position: absolute;
    left: 50px;
    bottom: 0;
    /* animation: ${animation}; */
    background: url(${box}) no-repeat;
    background-size: contain;

    ${props => props.shake && css`animation: ${shakeAnimation}`};
`;

const DropZone = () => {
    const [, dispatch] = useStateValue();
    const [shake, setShake] = useState(false);

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: ['plzen', 'mac', 'lambo', 'golf', 'ipad'],
        drop: item => handleSuccess(item),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const handleSuccess = ({ type }) => {
        if (type !== 'ipad') {
            setShake(true);
            setTimeout(() => {
                setShake(false);
            }, 600);
        } else {
            dispatch({
                type: 'completeDrag',
            });
        }
    };

    return <Box ref={drop} shake={shake} isOver={isOver} isActive={canDrop && isOver}/>;
};

export default DropZone;